import React from "react";
import ReactDOM from "react-dom";
import "./global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          n1: "Home",
          n2: "Our Business",
          n3: "Contact Us",
          h00: "Company Introduction",
          h0: "ABOUT US",
          h1: "Magnet & Amanda consulting company",
          h2:
            "Magnet & Amanda consulting company is a famous consulting company heading in the financial center of Asia, Singapore. Magnet & Amanda consulting company has a good understanding of the market situation, laws and regulations and investment environment of Singapore and other ASEAN countries as well as China, Japan and South Korea during its ten years of operation.",
          h3:
            "The company can provide consulting services for clients in strategy, operation, organization and merger and acquisition, especially in the area of risk assessment, enterprise internal control, overseas business development, risk management, etc. ",
          h33:
            "Our unique core values are giving, diversity, innovation, integrity and corporate responsibility.",
          h4: "Team",
          h5:
            "Our team members are mainly from the private sector, academia and government departments, with rich practical and theoretical experience. We are also committed to building a pool of experts, providing flexible cooperation networks and generous salaries to fulfill their career ambitions. We emphasize teamwork, trust and different opinions to help clients focus on their opportunities, build their ability to win the future.",
          h6: "Private sector",
          h7: "Financial expert",
          h8: "Government sector",
        },
      },
      zh: {
        translation: {
          n1: "首页",
          n2: "公司业务",
          n3: "联系我们",
          h00: "公司简介",
          h0: "关于·我们",
          h1: "曼达咨询公司",
          h2:
            "曼达咨询公司（Magnet & Amanda Consulting Inc）是一家著名的调查咨询公司，总部位于亚洲金融中心新加坡。曼达咨询公司在十年的运营过程中，对新加坡等东盟国家和中国、日本、韩国的市场现状、法律法规、投资环境非常了解。",
          h3:
            "公司能够为客户在战略、运营、组织以及兼并购方面提供咨询服务，特别擅长风险评估、企业内控、海外业务拓展、风险管理等。",
          h33:
            "我公司一直秉承着独特的核心价值，即给予、多元性、创新、诚信和企业责任。",
          h4: "我们的团队",
          h5:
            "我们的团队成员主要来自于私营部门、学术界和政府部门，具备丰富的实践和理论经验。我们还致力于建立专家库，提供灵活的合作网络以及丰厚的薪水，以实现他们的职业抱负。我们强调团队合作、信任和对不同意见的包容，帮助客户专注于他们的机会，建立他们的能力，赢得未来。",
          h6: "私营部门",
          h7: "金融专家",
          h8: "政府部门",
        },
      },
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
