import React from "react";
import logo from "./logo.svg";
import s from "./app.module.css";
import { Link } from "react-router-dom";
import Footer from "./footer";
import HomePage from "./home";
import BusinessPage from "./business";
import { useTranslation, initReactI18next } from "react-i18next";
import ContactPage from "./contact";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import logoIcon from "./img/logo2.png";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

function Content() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isHome = window.location.pathname === "/";
  const isBusiness =
    window.location.pathname &&
    window.location.pathname.indexOf("business") >= 0;
  const isContact =
    window.location.pathname &&
    window.location.pathname.indexOf("contact") >= 0;
  const path = window.location.pathname;
  const location = useLocation();
  const isEn = i18n.language == "en";
  const isZh = i18n.language == "zh";
  //{t("test")}
  //i18n.changeLanguage("en");
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, [path]);
  return (
    <div className={s.wrap}>
      <div className={s.header}>
        <div className={s.headerInner}>
          <h1>MANDA.</h1>
          <div className={s.headerNavWrap}>
            <span
              onClick={() => {
                navigate("/");
              }}
              className={isHome ? s.active : ""}
            >
              <span>{t("n1")}</span>
              <span className={s.dot} />
            </span>
            <span
              className={isBusiness ? s.active : ""}
              onClick={() => {
                navigate("/business");
              }}
            >
              <span>{t("n2")}</span>
              <span className={s.dot} />
            </span>

            <span
              className={isContact ? s.active : ""}
              onClick={() => {
                navigate("/contact");
              }}
            >
              <span>{t("n3")}</span>
              <span className={s.dot} />
            </span>
          </div>
        </div>
        <div className={s.langWrap}>
          <div
            onClick={() => {
              i18n.changeLanguage("en");
            }}
            className={isEn ? s.active : ""}
          >
            English
          </div>
          <div
            onClick={() => {
              i18n.changeLanguage("zh");
            }}
            className={isZh ? s.active : ""}
          >
            中文
          </div>
        </div>
      </div>
      <div className={s.content}>
        <Routes>
          <Route path="/business" element={<BusinessPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
      {!isContact && <Footer />}
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Content />
    </BrowserRouter>
  );
}

export default App;
