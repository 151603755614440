import s from "./s.module.css";
const Footer = () => {
  return (
    <div className={s.footer}>
      <div className={s.footerInner}>
        <div className={s.footerInfo}></div>
        <p className={s.footB}>Magnet & Amanda Consulting Inc.@2022</p>
      </div>
    </div>
  );
};

export default Footer;
