import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "../img/bbg.png";
import InfoIcon from "../img/b/bticon.png";
import I2 from "../img/b/i2.png";
import I3 from "../img/b/i3.png";
import b11 from "../img/b/l11.png";
import b12 from "../img/b/l12.png";
import b13 from "../img/b/l13.png";

import b21 from "../img/b/l21.png";
import b22 from "../img/b/l22.png";
import b23 from "../img/b/l23.png";

import b31 from "../img/b/l31.png";
import b32 from "../img/b/l32.png";
import b33 from "../img/b/l33.png";

import b41 from "../img/b/l41.png";
import b42 from "../img/b/l42.png";
import b43 from "../img/b/l43.png";
function Page() {
  return (
    <div className={s.wrap}>
      <div className={s.top}>
        <div className={s.topHolder} />
        <img src={Im1} />
        <div className={s.topIn}>
          <div className={s.hInfo}>
            <img src={InfoIcon} />
            <div className={s.iHead}>
              <span className={s.hLine}></span>
              <span>The Business Scope</span>
              <span className={s.hLine}></span>
            </div>
            {/* <div className={s.iHeadEn}>Scope of business</div> */}
          </div>
          <div className={s.topInfo}>
            <p>
              Magnet & Amanda consulting company provides services to clients in
              more than 40 countries, including fortune 500 enterprises, listed
              companies in various countries, and government agencies.
            </p>
            <p>
              Based on the forefront of business and technology, Magnet & Amanda
              consulting company's business scope covers more than 40
              industries. With its unique business experience and professional
              skills, Magnet & Amanda Consulting releases independent research
              reports to help customers achieve far-reaching changes and improve
              their performance.
            </p>
            <p>
              Professional service quality enjoys a high reputation among
              customers.
            </p>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.hInfo}>
          <img src={I2} />
          <div className={s.iHead}>
            <span className={s.hLine}></span>
            <span>Industries</span>
            <span className={s.hLine}></span>
          </div>
          {/* <div className={s.iHeadEn}>service trade</div> */}
        </div>
        <div className={s.vList}>
          <div className={s.item}>
            <div className={s.iTitle}>
              Government Agencies: Defense, Education, Health, Civil Service
            </div>
            <div className={s.iTitleIcon}>
              <img src={I3} />
            </div>
            <div className={s.imgList}>
              <img src={b11} />
              <img src={b12} />
              <img src={b13} />
            </div>
          </div>
          <div className={s.item}>
            <div className={s.iTitle}>
              Electronics and High Technology: Aerospace, Electronic Products,
              Software Equipment
            </div>
            <div className={s.iTitleIcon}>
              <img src={I3} />
            </div>
            <div className={s.imgList}>
              <img src={b21} />
              <img src={b22} />
              <img src={b23} />
            </div>
          </div>
          <div className={s.item}>
            <div className={s.iTitle}>
              Public Services: Electricity, Gas, Water Resources
            </div>
            <div className={s.iTitleIcon}>
              <img src={I3} />
            </div>
            <div className={s.imgList}>
              <img src={b31} />
              <img src={b32} />
              <img src={b33} />
            </div>
          </div>
          <div className={s.item}>
            <div className={s.iTitle}>
              Healthcare and Life Sciences: Government Healthcare,
              Pharmaceutical Products, Medical Products
            </div>
            <div className={s.iTitleIcon}>
              <img src={I3} />
            </div>
            <div className={s.imgList}>
              <img src={b41} />
              <img src={b42} />
              <img src={b43} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
