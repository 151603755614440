import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "../img/c/i1.png";
import InfoIcon from "../img/c/bticon.png";

function Page() {
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [market, setMarket] = React.useState("新加坡");
  const [content, setContent] = React.useState("");
  let navigate = useNavigate();
  const body = encodeURIComponent(`
    我是${name}, 计划成立${market}公司

    ${content}

    我的联系邮箱是: ${email}
  `);
  const aUrl = `mailto: contact@maconsulting.asia?subject=商业合作咨询-${name}&body=${body}`;
  return (
    <div className={s.wrap}>
      <div className={s.top}>
        <div className={s.topHolder} />
        <div className={s.topIn}>
          <div className={s.hInfo}>
            <img src={InfoIcon} />
            <div className={s.iHead}>
              <span className={s.hLine}></span>
              <span>Contact us</span>
              <span className={s.hLine}></span>
            </div>
            {/* <div className={s.iHeadEn}>contact us</div> */}
          </div>
          <div className={s.s1ContentWrap}>
            <div className={s.c1Item}>
              <div className={s.c1label}>Select Company</div>
              <div className={s.c1Value}>
                <select
                  defaultChecked={false}
                  defaultValue="新加坡"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setMarket(e.target.value);
                  }}
                >
                  <option value="Singapore">Singapore</option>
                  <option value="HongKong">Hong Kong</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="The British Virgin Islands">
                    The British Virgin Islands
                  </option>
                  <option value="Vietnam">Vietnam</option>
                </select>
              </div>
            </div>
            <div className={s.c1Item}>
              <div className={s.c1label}>Name</div>
              <div className={s.c1Value}>
                <input
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={s.c1Item}>
              <div className={s.c1label}>Your Email</div>
              <div className={s.c1Value}>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={s.c1Item}>
              <div className={s.c1label}>Input message</div>
              <div className={s.c1Value}>
                <textarea
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={s.c1Button}>
              <span
                onClick={() => {
                  if (!name || !content || !email || !market) {
                    return;
                  }
                  window.open(aUrl);
                }}
                className={
                  !name || !content || !email || !market ? s.disable : s.normal
                }
              >
                Submit
              </span>
            </div>
            <div className={s.footer}>
              <div className={s.footerInner}>
                <div className={s.footerInfo}></div>
                <p className={s.footB}>Magnet & Amanda Consulting Inc.@2022</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
