import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import Im1 from "../img/homebg.png";
import Im2 from "../img/homeIcon.png";
import H1 from "../img/homei1.png";
import H2 from "../img/homei2.png";
import H3 from "../img/homei3.png";

import Icon2 from "../img/homeIcon2.png";

import Siyin from "../img/siyin.png";
import Jinrong from "../img/jinrong.png";
import Zhengfu from "../img/zhengfu.png";

function Page() {
  const { t, i18n } = useTranslation();
  const [animation, setAnimation] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 200);
  }, []);

  return (
    <div className={animation ? `${s.wrap} ${s.animation}` : s.wrap}>
      <div className={s.top}>
        <img src={Im1} />
        <div className={s.topIn}>
          {/* <div className={s.topTitle}>关于·我们</div> */}
          <div className={s.topSub}>{t("h0")}</div>
        </div>
      </div>
      <div className={s.out}>
        <div className={s.hInfo}>
          <img src={Im2} />
          <div className={s.iHead}>
            <span className={s.hLine}></span>
            <span>{t("h00")}</span>
            <span className={s.hLine}></span>
          </div>
          {/* <div className={s.iHeadEn}>Company Introduction</div> */}
        </div>
        <div className={s.content}>
          <div className={s.left}>
            <div className={s.l1}>
              <img src={H1} />
            </div>
            <div className={s.l2}>
              <img className={s.l21} src={H2} />
              <img className={s.l22} src={H3} />
            </div>
          </div>
          <div className={s.right}>
            <h3>{t("h1")}</h3>
            <p>{t("h2")}</p>
            <p>{t("h3")}</p>
            <p className={s.pQ}>{t("h33")}</p>
          </div>
        </div>

        <div className={s.hInfo}>
          <img src={Icon2} />
          <div className={s.iHead}>
            <span className={s.hLine}></span>
            <span>{t("h4")}</span>
            <span className={s.hLine}></span>
          </div>
          {/* <div className={s.iHeadEn}>Team members</div> */}
        </div>
        <div className={s.content2}>
          <p>{t("h5")}</p>
          <div className={s.list}>
            <div className={s.item}>
              <div className={s.itemIcon}>
                <img src={Siyin} />
              </div>
              <div className={s.itemtext}>{t("h6")}</div>
            </div>
            <div className={s.item}>
              <div className={s.itemIcon}>
                <img src={Jinrong} />
              </div>
              <div className={s.itemtext}>{t("h7")}</div>
            </div>
            <div className={s.item}>
              <div className={s.itemIcon}>
                <img src={Zhengfu} />
              </div>
              <div className={s.itemtext}>{t("h8")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
